import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { preSearchVisible } from '../../../../store/search/action-creators'
import { IPreSearchItem } from '../../../../store/search/types'
import { ReduxState } from '../../../../store/reducers'

import Loader from '../../../loader/Loader'

import noPhoto from '../../../../assets/images/no-photo.svg'

interface IPreSearch {
  preSearchVisible: (isVisible: boolean) => void
  preSearchIsVisible: boolean
  preSearchLoading: boolean
  preSearchResult: IPreSearchItem[]
  onSearch: () => void
}

const PreSearch: React.FunctionComponent<IPreSearch> = (props) => {
  if (!props.preSearchIsVisible) {
    return null
  }

  return (
    <div className="header__search-results">
      {
        props.preSearchLoading
          ? <Loader color='orange' className='product-item'/>
          : <div className="header__search-results__content">
              {
                props.preSearchResult.length
                  ? (
                      <>
                        <div className="header__search-results__content-list">
                          {
                            props.preSearchResult.map((item, i) => (
                              <Link to={item.link} className="pre-search-item" key={i} onClick={() => props.preSearchVisible(false)}>
                                <img className="pre-search-item__image" src={item.image || noPhoto} alt={item.name} />
                                <div className="pre-search-item__name">{item.name}</div>
                                {
                                  item.title
                                    ? <div className="pre-search-item__title">{item.title}</div>
                                    : <div className="pre-search-item__price">{item.price}</div>
                                }
                                <div className="pre-search-item__icon"></div>
                              </Link>
                            ))
                          }
                        </div>
                        <div className="header__search-results__content-more" onClick={props.onSearch}>
                          <span className='more-icon'></span>
                          <span>Show more</span>
                        </div>
                      </>
                    )
                  : <div className="header__search-results__content--empty">No results</div>
              }
            </div>
      }
    </div>
  )
}

export default connect((state: ReduxState) => {
  return {
    preSearchIsVisible: state.search.preSearchVisible,
    preSearchResult: state.search.preSearchResult,
    preSearchLoading: state.search.preSearchLoading
  }
}, {
  preSearchVisible
})(PreSearch)
