import priceToString from '../../utils/PriceToString'
import { ICart } from '../../store/cart/types'

export function getCartCheckoutOptions (props: ICart) {
  const options = []
  options.push({
    label: 'Subtotal',
    value: priceToString(props.subtotal)
  })
  let benefitsFromCoupons = 0
  let benefitsFromDiscount = 0
  const usedBonuses = props.bonuses_to_use
  const driverTips = props.driver_tips
  if (props.benefits && props.benefits.coupons) {
    benefitsFromCoupons = props.benefits.coupons.reduce((acc, item) => {
      acc += item.saved_amount
      return acc
    }, 0)
  }
  if (props.benefits && props.benefits.discounts) {
    benefitsFromDiscount = props.benefits.discounts.reduce((acc, item) => {
      acc += item.saved_amount
      return acc
    }, 0)
  }
  if (props.tax) {
    options.push({ label: 'Sales tax', value: priceToString(props.tax) })
  }
  if (props.shipping) {
    options.push({ label: 'Shipping fee', value: priceToString(props.shipping), description: props.shipping_fee_message })
  }
  if (props.service_fee) {
    options.push({ label: 'Service fee', value: priceToString(props.service_fee) })
  }
  options.push({ label: 'Shipping & handling', value: 'FREE' })

  if (benefitsFromCoupons) {
    options.push({
      label: 'Coupons',
      value: priceToString(benefitsFromCoupons)
    })
  }

  if (benefitsFromDiscount) {
    options.push({
      label: 'Discount',
      value: priceToString(benefitsFromDiscount)
    })
  }

  if (usedBonuses) {
    options.push({
      label: 'Bonuses',
      value: priceToString(usedBonuses)
    })
  }

  if (driverTips) {
    options.push({
      label: 'Driver Tips',
      value: priceToString(driverTips)
    })
  }

  return options
}
