import { CatalogItem } from '../catalog/types'
import { ICoupon } from '../order/types'
import { IAddress } from '../customers/types'

export const GET_CART = 'GET_CART'
export const GET_MULTI_CART = 'GET_MULTI_CART'
export const SHOW_CART = 'SHOW_CART'
export const HIDE_CART = 'HIDE_CART'

interface ShowCartAction {
  type: typeof SHOW_CART
}

interface HideCartAction {
  type: typeof HIDE_CART
}

interface GetCartAction {
  type: typeof GET_CART,
  payload: any
}

interface GetMultiCartAction {
  type: typeof GET_MULTI_CART,
  payload: any
}

export const DROP_CART = 'DROP_CART'

interface DropCartAction {
  type: typeof DROP_CART
}

export const INCREMENT_CART_ITEM = 'INCREMENT_CART_ITEM'

export interface IcrementCartItem {
  type: typeof INCREMENT_CART_ITEM,
  payload: {
    id: string,
    count?: number
  }
}

export const DECREMENT_CART_ITEM = 'DECREMENT_CART_ITEM'

export interface DecrementCartItem {
  type: typeof DECREMENT_CART_ITEM,
  payload: {
    id: string,
    count?: number
  }
}

export interface CartItem {
  count: number
  product: CatalogItem
}

export type CartActions = ShowCartAction | HideCartAction | GetCartAction| DropCartAction | IcrementCartItem | DecrementCartItem | GetMultiCartAction

export interface CartSavedAmount {
  saved_amount: number
}

export interface ICartDiscountGift {
  product_id: number
  product: CatalogItem
  count: number
}

export interface CartDiscount extends CartSavedAmount {
  discount_id: number
  gift?: ICartDiscountGift
}

export interface CartCoupon extends CartSavedAmount {
  coupon_id: number
}

export interface CartBenefits {
  discounts: CartDiscount[]
  coupons: CartCoupon[]
}

export interface ICartStoreNetwork {
  id: number
  name: string
  details: string
  image: string | null
}

interface IDeliveryType {
  id: number
  name: string
}

export interface ICartStore {
  id: number
  name: string
  address: IAddress
  network: ICartStoreNetwork
  delivery_types: IDeliveryType[]
}

export interface ICart {
  items: CartItem[]
  loading?: boolean
  benefits?: CartBenefits
  total: number
  subtotal: number
  tax?: number
  coupons: ICoupon[]
  bonuses_to_use?: number
  driver_tips?: number
  cartStore: ICartStore
  service_fee: number
  shipping: number
  shipping_fee_message: string
}

export interface Cart {
  items: CartItem[]
  loading: boolean
  benefits?: CartBenefits
  total: number
  subtotal: number
  tax?: number
  coupons: ICoupon[]
  bonuses_to_use?: number
  driver_tips?: number
  carts: ICart[]
}

export interface CartState extends Cart {
  cartIsShown: boolean
}
