import {
  CartState,
  CartActions,
  DECREMENT_CART_ITEM,
  DROP_CART,
  GET_CART,
  GET_MULTI_CART,
  ICart,
  INCREMENT_CART_ITEM,
  SHOW_CART,
  HIDE_CART
} from './types'

const initialState = {
  items: [],
  pagingHeader: {},
  loading: true,
  total: 0,
  subtotal: 0,
  coupons: [],
  bonuses_to_use: 0,
  carts: [],
  cartIsShown: false,
}

export default function reducer (state: CartState = initialState, action: CartActions) {
  switch (action.type) {
    case GET_CART: {
      const {
        products: items = [],
        paging_header: pagingHeader = {},
        total = 0,
        coupons = [],
        benefits = {},
        tax = 0,
        subtotal = 0,
        bonuses_to_use = 0,
        driver_tips = 0,
        service_fee = 0,
        shipping = 0
      } = action.payload || {}
      return {
        ...state,
        coupons,
        benefits,
        items,
        pagingHeader,
        total,
        loading: false,
        tax,
        subtotal,
        bonuses_to_use,
        driver_tips,
        service_fee,
        shipping
      }
    }
    case GET_MULTI_CART: {
      const cartItems: ICart[] = []
      const carts = action.payload.map((cart: any) => {
        const {
          products: items = [],
          total = 0,
          coupons = [],
          benefits = {},
          tax = 0,
          subtotal = 0,
          bonuses_to_use = 0,
          driver_tips = 0,
          store = {},
          service_fee = 0,
          shipping = 0,
          shipping_fee_message = 0
        } = cart || {}
        const item = {
          coupons,
          benefits,
          items,
          total,
          tax,
          subtotal,
          bonuses_to_use,
          driver_tips,
          cartStore: store,
          service_fee,
          shipping,
          shipping_fee_message
        }
        cartItems.push(...items)
        return {
          ...item
        }
      })
      return {
        ...state,
        carts,
        items: cartItems
      }
    }
    case DROP_CART:
      return {
        ...initialState
      }
    case SHOW_CART:
      return {
        ...state,
        cartIsShown: true
      }
    case HIDE_CART:
      return {
        ...state,
        cartIsShown: false
      }
    case DECREMENT_CART_ITEM:
    case INCREMENT_CART_ITEM: {
      const { items } = state
      const { id: incrementId, count } = action.payload
      const item = items.find(({ product: { id } }) => id === incrementId)
      if (item && count) {
        action.type === INCREMENT_CART_ITEM
          ? item.count += count
          : item.count -= count
      }
      return {
        ...state,
        items: items.filter(({ count }) => count)
      }
    }
    default:
      return state
  }
}
