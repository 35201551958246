import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import priceToString from '../../utils/PriceToString'
import { Cart } from '../../store/cart/types'
import Checkout from '../checkout/Checkout'
import { getCartCheckoutOptions } from '../cart/CartHelpers'
import { ReduxState } from '../../store/reducers'

interface ICartSidebarProps extends Cart {
  storeId?: undefined | number
  checkoutOptions?: {
    label: string,
    value: string
  }[]
}

const CartSidebar: React.FC<ICartSidebarProps & RouteComponentProps> = (props) => {
  const cart = props.carts.find(item => item.cartStore && item.cartStore.id === props.storeId) || props.carts[0] || {}

  const [checkoutOptions, setCheckoutOptions] = useState([
    { label: 'Subtotal', value: '0' }
  ])
  useEffect(() => {
    const options = getCartCheckoutOptions({
      ...cart
    })
    setCheckoutOptions(options)
  }, [cart.total, cart.items])

  return (
    <Checkout
      total={priceToString(cart.total)}
      options={checkoutOptions}
    />
  )
}

export default connect(
  ({ cart }: ReduxState) => ({ ...cart }),
  null
)(CartSidebar)
